%clearfix {
  content: '';
  display: block;
  clear: both; }
%psevdo {
  content: '';
  position: absolute;
  display: block; }

%flex-between {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
%flex-evenly {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly; }
%flex-between-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end; }
%flex-between-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
%flex-evenly-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end; }
%flex-between-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
%flex-evenly-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start; }
%flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }

@mixin flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
@mixin psevdo {
  content: '';
  position: absolute;
  display: block; }

@mixin clearfix {
  content: '';
  display: block;
  clear: both; }

@mixin flex-between {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
@mixin flex-evenly {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly; }
@mixin flex-between-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end; }
@mixin flex-between-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
@mixin flex-evenly-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-end; }
@mixin flex-between-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
@mixin flex-evenly-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start; }
@mixin flex-column {
  display: flex;
  flex-direction: column; }
