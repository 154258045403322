@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.ttf') format('truetype'), url('../fonts/Roboto-Medium.svg') format('svg');
  font-weight: 600;
  font-style: normal; }
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.woff') format('woff'), url('../fonts/Roboto-Bold.woff2') format('woff2'), url('../fonts/Roboto-Bold.ttf') format('truetype'), url('../fonts/Roboto-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal; }
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Black.eot');
  src: url('../fonts/Roboto-Black.woff') format('woff'), url('../fonts/Roboto-Black.woff2') format('woff2'), url('../fonts/Roboto-Black.ttf') format('truetype'), url('../fonts/Roboto-Black.svg') format('svg');
  font-weight: 800;
  font-style: normal; }
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-LightItalic.eot');
  src: url('../fonts/Roboto-LightItalic.woff') format('woff'), url('../fonts/Roboto-LightItalic.woff2') format('woff2'), url('../fonts/Roboto-LightItalic.ttf') format('truetype'), url('../fonts/Roboto-LightItalic.svg') format('svg');
  font-weight: 300;
  font-style: italic; }
@font-face {
  font-family: 'BebasNeue-Regular';
  src: url('../fonts/BebasNeue-Regular.eot');
  src: url('../fonts/BebasNeue-Regular.woff') format('woff'), url('../fonts/BebasNeue-Regular.woff2') format('woff2'), url('../fonts/BebasNeue-Regular.ttf') format('truetype'), url('../fonts/BebasNeue-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'BebasNeue-Bold';
  src: url('../fonts/BebasNeue-Bold.eot');
  src: url('../fonts/BebasNeue-Bold.woff') format('woff'), url('../fonts/BebasNeue-Bold.woff2') format('woff2'), url('../fonts/BebasNeue-Bold.ttf') format('truetype'), url('../fonts/BebasNeue-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'BrushScriptMT';
  src: url('../fonts/BrushScriptMT.eot');
  src: url('../fonts/BrushScriptMT.woff') format('woff'), url('../fonts/BrushScriptMT.woff2') format('woff2'), url('../fonts/BrushScriptMT.ttf') format('truetype'), url('../fonts/BrushScriptMT.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Panton-Regular';
  src: url('../fonts/Panton-Regular.eot');
  src: url('../fonts/Panton-Regular.woff') format('woff'),  url('../fonts/Panton-Regular.ttf') format('truetype'), url('../fonts/Panton-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Panton-Bold';
  src: url('../fonts/Panton-Bold.eot');
  src: url('../fonts/Panton-Bold.woff') format('woff'),  url('../fonts/Panton-Bold.ttf') format('truetype'), url('../fonts/Panton-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Panton-Italic';
  src: url('../fonts/Panton-Italic.eot');
  src: url('../fonts/Panton-Italic.woff') format('woff'),  url('../fonts/Panton-Italic.ttf') format('truetype'), url('../fonts/Panton-Italic.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Quentin';
  src: url('../fonts/Quentin.eot');
  src: url('../fonts/Quentin.woff') format('woff'),  url('../fonts/Quentin.ttf') format('truetype'), url('../fonts/Quentin.svg') format('svg');
  font-weight: normal;
  font-style: normal; }
