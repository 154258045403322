
@import 'helpers/mixins';

@import 'layout/__reset';
@import 'layout/__fonts';
@import 'layout/__respond';
@import 'layout/__extend';
@import 'helpers/_var';

img {
  max-width: 100%;
  max-height: 100%; }
.bold {
  font-weight: bold; }
.btn-default {
  background-color: #ff9d00;
  box-shadow: 0 10px #b16200;
  transition: .5s all; }
.btn-default:hover {
  background-color: #2ea980;
  box-shadow: 0 10px #0a3e27; }
.btn-default:active {
  background-color: #2ea980;
  box-shadow: 0 5px #0a3e27;
  transform: translateY(5px); }
.btn-default:focus {
  outline: none; }


body {
  font-family: 'Roboto';
  background-color: $white;
  font-weight: normal;
  color: #000;
  padding: 0 !important;
  font-size: 14px; }

.wr-all {
  overflow: hidden;
  width: 100%; }

.wr {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $white;
  background-size: cover;
  z-index: 99;
  font-size: 14px;
  margin: 0 auto;
  position: relative;
  //overflow: hidden
  .block,.block2 {
    position: relative;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 320px; }
  .block1 {
    width: 100%; } }

.wr.wr01 {
  background-color: $green; }
.wr.wr0 {
  z-index: 100;
  background-color: transparent;
  &::before {
    @include psevdo;
    width: 140%;
    top: -28px;
    left: -10%;
    height: 125%;
    z-index: -1;
    transform: rotate(2deg);
    background-image: linear-gradient(to right, #0ec1c2 0%, #0eab5c 20%, #0eab5c 60%, #0ec1c2 100%);
    box-shadow: 0 0 15px 1px rgba(0,0,0,0.2); }
  .block {
    text-align: center;
    padding-bottom: 20px;
    &::before {
      @include psevdo;
      width: 200px;
      height: 180px;
      background: url(../img/sheet.png) 0 0 no-repeat;
      background-size: 100% auto;
      top: 0px;
      right: 85%; }
    .img {
      width: 40px;
      display: inline-block;
      vertical-align: middle;
 }      //padding: 5px
    .text {
      font-family: 'Panton-Bold';
      font-size: 42px;
      text-transform: uppercase;
      display: inline-block;
      color: $white;
      vertical-align: middle; } } }
.wr.wr1 {
  background-color: #10a676;
  background-image: url("../img/wr1.png");
  margin-top: -40px;
  padding-top: 28px;
  .block {
    @include flex-between-top;
    .img-girl {
      width: 120px;
      height: 300px;
      position: relative;
      z-index: 2;
      img {
        max-width: unset;
        max-height: unset; } }
    .right {
      width: 180px;
      padding-top: 20px;
      .text {
        font-family: 'BebasNeue-Bold';
        font-size: 26px;
        text-transform: uppercase;
        text-align: right;
        color: $white; }
      .img1 {
        width: 260px;
        margin-left: -20px;
        margin-top: 40px; }
      .img-product {
        margin-top: -115%; }
      .img2 {
        margin-top: -35%;
        margin-left: -20%; } } } }
.wr.wr2 {
  //background: url("../img/wr2.png") top center no-repeat
  //background-size: cover
  background-color: $green;
  z-index: 100;
  .block {
    padding-top: 20px;
    padding-bottom: 20px;
    .img-fruit {
      width: 160px;
      position: absolute;
      top: -50px;
      left: -20px; }
    .item1 {
      .item-title {
        font-weight: bold;
        color: $yellow;
        font-size: 25px;
        text-align: center; }
      .list {
        padding-left: 25px;
        padding-right: 25px;
        li {
          color: $white;
          font-size: 25px;
          font-weight: bold;
          padding-left: 40px;
          position: relative;
          margin-top: 20px;
          &::before {
            @include psevdo;
            border-bottom: 6px solid $white;
            border-left: 6px solid $white;
            width: 13px;
            height: 13px;
            transform: rotate(225deg);
            left: 0px;
            top: 5px; } } } }
    .item2 {
      margin-top: 20px;
      text-align: center;
      color: $yellow;
      font-size: 19px;
      font-weight: bold;
      line-height: 1.3; }
    .item3 {
      .btn-default {
        margin: 15px auto 0 auto;
        width: 85%;
        color: #ffffff;
        text-transform: uppercase;
        font-family: "BebasNeue-Bold";
        text-align: center;
        border: none;
        border-radius: 10px;
        padding: 6px 15px;
        display: block;
        font-size: 36px; } }
    .item4 {
      margin-top: 25px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      .text1 {
        color: #5dd2b1;
        text-decoration: line-through; }
      .text2 {
        color: $yellow;
        display: inline-block;
        margin-left: 5px; } } }
  .img-flower {
    position: absolute;
    bottom: -30px;
    right: -6%;
    z-index: 2; } }
.wr.wr3_0 {
  background-image: url('../img/wr3.png'),linear-gradient($orange 0%, #f4aa3e 40%, $orange 100%);
  background-size: auto 100%;
  width: 300%;
  left: -100%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  overflow: hidden;
  z-index: 99; }
.wr.wr3 {
  background-color: transparent;
  .block {
    .item-body {
      padding-left: 10px;
      padding-right: 10px;
      .item {
        @include flex-between-center;
        padding-top: 20px;
        .item-img {
          width: 45%; }
        .item-text {
          width: 52%;
          color: $white;
          font-family: 'BebasNeue-Bold';
          font-size: 26px;
          text-transform: uppercase; } }
      .item1,.item3 {
        .item-text {
          text-align: right; } } }
    .img-fruit {
      display: inline-block;
      margin-left: -16%;
      margin-top: -14%; } } }
.wr.wr4 {
  background: url("../img/wr4.png") center center no-repeat, url("../img/wr4_1.png") top center no-repeat;
  background-size: cover;
  margin-top: -10%;
  padding-top: 10%;
  z-index: 98;
  .block {
    padding-top: 15px;
    padding-bottom: 15px;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 19px; }
    .item-body {
      margin-top: 15px;
      padding-left: 5px;
      padding-right: 15px;
      @include flex-between-center;
      .item-doc {
        overflow: hidden;
        height: 150px;
        width: 150px;
        border-radius: 50%;
        box-shadow: 0 0 20px 1px rgba(0,0,0,0.2);
        img {
          max-width: unset;
          max-height: unset;
          margin-left: -60px;
          margin-top: -30px; } }
      .item-box {
        width: 100px;
        .item-name {
          font-size: 12px;
          .bold {
            font-size: 11px; } }
        .item-sign {
          font-family: 'Quentin';
          font-size: 21px;
          color: #0960ac;
          transform: rotate(-10deg);
          margin-top: 15px;
          text-align: center; }
        .item-print {
          text-align: center;
          margin-top: 15px; } } }
    .text {
      text-align: center;
      margin-top: 15px; }
    .img-flower {
      position: absolute;
      bottom: -30px;
      left: -6%;
      z-index: 2; } } }
.wr.wr5 {
  background-image: url("../img/wr5.png");
  background-color: $blue;
  z-index: 97;
  .block {
    padding-top: 30px;
    padding-bottom: 20px;
    .title {
      font-family: 'BebasNeue-Bold';
      font-size: 30px;
      text-align: center;
      color: $yellow;
      text-transform: uppercase; }
    .item-body {
      padding-right: 10px;
      padding-left: 10px;
      .item {
        @include flex-between-center;
        margin-top: 40px;
        position: relative;
        .item-img {
          width: 30%; }
        .item-box {
          width: 65%;
          font-family: 'Panton-Bold';
          .item-title {
            color: #ffe400;
            font-size: 20px; }
          .item-text {
            font-size: 16px;
            color: $white;
            margin-top: 5px; } } }
      .item1 .item-box,.item3 .item-box {
        text-align: right; }
      .item1::after {
        @include psevdo;
        width: 100px;
        height: 65px;
        top: 105%;
        left: 38%;
        background: url('../img/arrow-right.png') 0 0 no-repeat; }
      .item2::after {
        @include psevdo;
        width: 100px;
        height: 65px;
        top: 100%;
        left: 30%;
        background: url('../img/arrow-right.png') 0 0 no-repeat;
        transform: scale(-1, 1); }
      .item2::before {
        @include psevdo;
        width: 100px;
        height: 70px;
        background: url('../img/wr5-img1.png') 0 0 no-repeat;
        top: -22%;
        right: -22%;
        z-index: -1; }
      .item3::before {
        @include psevdo;
        width: 120px;
        height: 120px;
        background: url('../img/wr5-img3.png') 0 0 no-repeat;
        top: -45%;
        left: -27%;
        z-index: -1; } }
    .btn-group {
      .btn-default {
        margin: 15px auto 0 auto;
        width: 90%;
        color: #ffffff;
        text-transform: uppercase;
        font-family: "BebasNeue-Bold";
        text-align: center;
        border: none;
        border-radius: 25px;
        padding: 10px 15px;
        display: block;
        font-size: 27px; } }
    .bottom-text {
      margin-top: 30px;
      color: $white;
      text-align: center;
      font-family: 'Panton-Bold';
      line-height: 1.5;
      font-size: 17px; }
    .img-apel {
      position: absolute;
      bottom: -30px;
      right: -15%;
      z-index: 2;
      width: 125px;
      height: 90px; } } }
.wr.wr6 {
  background-image: url("../img/wr7.png"), linear-gradient(#b4e46b 0%,#72aa38 50%,#b4e46b 100%);
  //background-color: #b4e46b
  z-index: 96;
  .block {
    padding-top: 30px;
    padding-bottom: 30px;
    .title {
      font-family: 'Panton-Bold';
      font-size: 26px;
      text-align: center;
      text-transform: uppercase;
      color: $white; }
    .item {
      margin-top: 15px;
      color: $white;
      text-align: center;
      position: relative;
      .item-title {
        font-family: 'Panton-Bold';
        font-size: 18px;
        text-transform: uppercase;
        margin-top: 15px; }
      .item-text {
        font-size: 18px;
        margin-top: 15px;
        font-family: 'Panton-Regular'; } }
    .item1::before {
      @include psevdo;
      width: 145px;
      height: 120px;
      background: url('../img/wr7-img3.png') 0 0 no-repeat;
      top: -20%;
      left: -15%;
      z-index: -1; }
    .item3::before {
      @include psevdo;
      width: 145px;
      height: 120px;
      background: url('../img/wr7-img5.png') 0 0 no-repeat;
      top: 5%;
      left: -15%;
      z-index: -1; }
    .item4::before {
      @include psevdo;
      width: 145px;
      height: 120px;
      background: url('../img/flower.png') 0 0 no-repeat;
      bottom: 25%;
      left: -15%;
      z-index: -1; }
    .item4::after {
      @include psevdo;
      width: 145px;
      height: 120px;
      background: url('../img/wr7-img4.png') 0 0 no-repeat;
      bottom: 55%;
      left: 85%;
      z-index: -1; } } }
.wr.wr7 {
  .block {
    padding-top: 20px;
    padding-bottom: 20px;
    .title {
      color: #0aaa15;
      font-family: 'BebasNeue-Bold';
      font-size: 35px;
      text-transform: uppercase;
      text-align: center; }
    .list {
      padding-left: 10px;
      padding-right: 10px;
      li {
        padding-left: 60px;
        font-family: 'Panton-Regular';
        font-size: 20px;
        margin-top: 30px;
        position: relative;
        &::before {
          @include psevdo;
          width: 55px;
          height: 60px;
          top: 0px;
          left: 0px; } }
      .li1::before {
        background: url('../img/wr8-item1.png') 0 0 no-repeat; }
      .li2::before {
        background: url('../img/wr8-item2.png') 0 0 no-repeat; }
      .li3::before {
        background: url('../img/wr8-item3.png') 0 0 no-repeat; } } } }
.wr.wr8 {
  background-image: linear-gradient(#1271ca 0%,#1da6e3 50%,#1271ca 100%);
  .block {
    padding-top: 20px;
    padding-bottom: 20px;
    .flower {
      position: absolute;
      left: -22%;
      z-index: -1;
      top: 2%; }
    .flower2 {
      position: absolute;
      right: -22%;
      z-index: -1;
      top: -5%; }
    .butterfly {
      position: absolute;
      right: -3%;
      bottom: 10.5%; }
    .title {
      color: $white;
      font-family: 'BebasNeue-Bold';
      font-size: 26px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px; }
    .item-body {
      margin-top: 10px;
      background-color: $white;
      border-radius: 10px;
      padding: 15px 20px;
      .item1 {
        .img-min {
          display: inline-block;
          vertical-align: middle;
          width: 10%; }
        .item-name {
          display: inline-block;
          vertical-align: middle;
          font-weight: bold;
          font-size: 14px;
          color: #3280c9;
          max-width: 80%;
          margin-left: 5px; } }
      .item2 {
        font-family: 'Panton-Regular';
        font-size: 14px;
        margin-top: 10px; }
      .item3 {
        margin-top: 15px;
        text-align: center; } } } }
.wr.wr9 {
  background-image: url("../img/wr10.png");
  .block {
    padding-top: 15px;
    padding-bottom: 20px;
    .title {
      color: #ff1d2d;
      font-family: 'BebasNeue-Bold';
      font-size: 38px;
      text-transform: uppercase;
      text-align: center; }
    .img-box {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 15px;
      text-align: center;
      .img-cert,.img-product {
        display: inline-block;
        vertical-align: top; }
      .img-cert {
        width: 35%;
        margin-top: 10px; }
      .img-product {
        width: 60%;
        img {
          max-width: unset;
          max-height: unset; } } }
    .text {
      font-family: 'Panton-Regular';
      font-size: 18px;
      text-align: center; } } }
.wr.wr10 {
  background-image: url("../img/wr11.png");
  background-color: #4d8ad0;
  .block {
    padding-top: 15px;
    padding-bottom: 30px;
    .title {
      color: #1f4575;
      font-family: 'BebasNeue-Bold';
      font-size: 33px;
      text-transform: uppercase;
      text-align: center; }
    .img-girl {
      position: absolute;
      z-index: -1;
      left: -6%;
      margin-top: 4%;
      width: 240px; }
    .item-body {
      margin-top: 10px;
      padding-left: 40%;
      .item-title {
        font-family: 'Panton-Bold';
        color: #1f4575;
        margin-bottom: 5px;
        font-size: 18px; }
      .item {
        background-color: #ff7941;
        padding: 10px;
        color: $white;
        font-family: 'Panton-Bold';
        font-size: 15px;
        margin-top: 10px; } } } }
.wr.wr11 {
  background-image: linear-gradient(#329451 0%,#38b064 50%,#329451 100%);
  .block {
    padding-top: 15px;
    padding-bottom: 15px; }
  .title {
    font-family: 'Panton-Bold';
    font-size: 18px;
    text-align: center;
    color: $white; }
  .price {
    font-family: 'Panton-Bold';
    font-size: 20px;
    text-align: center;
    .text1 {
      color: #59ed91;
      span {
        text-decoration: line-through;
        margin-left: 5px;
        display: inline-block; } }
    .text2 {
      color: $yellow;
      margin-top: 5px; } }
  .form-block {
    padding-right: 20px;
    padding-left: 20px;
    .form-control {
      width: 100%;
      background-color: #ffffff;
      border-radius: 10px;
      margin-top: 10px;
      border: none;
      font-family: 'Panton-Italic';
      padding: 15px;
      text-align: center;
      font-size: 16px;
      box-shadow: inset 3px 3px 5px #d9d9d9; }
    .btn-default {
      margin: 15px auto 0 auto;
      width: 100%;
      color: #ffffff;
      text-transform: uppercase;
      font-family: "BebasNeue-Bold";
      text-align: center;
      border: none;
      border-radius: 10px;
      padding: 10px 15px;
      display: block;
      font-size: 33px; } }
  .bottom-text {
    font-family: 'Panton-Bold';
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    color: $white; }
  .block1 {
    background-color: #e53d3d;
    transform: rotate(-3deg);
    margin-top: 10px;
    .subtitle {
      text-align: center;
      font-family: 'BebasNeue-Bold';
      font-size: 16px;
      text-transform: uppercase;
      color: $white;
      padding: 5px; }
    .img-sale {
      position: absolute;
      top: -9px;
      left: 85%;
      width: 60px;
      img {
        max-width: 35px; } } } }
.wr.wr12 {
  background-color: #235a34;
  .block {
    text-align: center;
    padding: 20px 0;
    .text {
      color: #ffffff; }
    .privacy {
      color: #0581cc;
      display: block;
      margin-top: 5px;
      &:hover {
        text-decoration: underline; } } } }



@media screen and (min-width: 700px) {
  .wr.wr1 {
    width: 140%;
    left: -20%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%; }
  .wr.wr0::before {
    background-image: linear-gradient(to right, #0ec1c2 0%, #0eab5c 40%, #0ec1c2 100%); } }
//@media screen and (min-width: 800px) and (max-width: 1399px)
//.wr.wr0::after
//  top: -340%
//@media screen and (min-width: 1400px)
//.wr.wr0::after
//  top: -280%
